/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Text from '../components/ingka/Text'
import { useTranslation } from 'react-i18next'
import Select from "../components/global/Select"
import { getAppRoles, getAppUsers, getGlobalUsers, updateUser } from '../services/userServices'
import Button from '../components/ingka/Button'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../components/global/Table'
import Load from '../components/ingka/Load'
import { ROL } from '../configuration/globalConstants'
import ConfirmationModal from '../components/global/ConfirmationModal';
import transformToOption from '../utils/transformToOptionSelect'
import Switch from '../components/ingka/Switch';
import SSRIcon from '@ingka/ssr-icon'
import eye from '@ingka/ssr-icon/paths/visibility-show'
import { createMsgToast } from '../store/globalSlice'

export default function Users() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()
  const [appUsers, setAppUsers] = useState(null)
  const [filteredAppUsers, setFilteredAppUsers] = useState(null)
  const [globalUsers, setGlobalUsers] = useState([])
  const [userSelected, setUserSelected] = useState(null)
  const [roles, setRoles] = useState(null)
  const [roleSelected, setRoleSelected] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingGlobal, setLoadingGlobal] = useState(false)
  const[confirmation, setConfirmation] = useState(null)

  const role = useSelector((state) => state.global.rol)
  const myStore = useSelector((state) => state.global.myStore)

  const cellRole = ({row}) => {
    return <span>{row.original.roles[0]?.name}</span>
  }
  const cellStatus = ({row}) => {
    return <Switch
              props={{
                className: "zoom08",
                value: row.original.active,
                checked: row.original.active,
                onChange: e => handleActive(e.target.checked, row.original),
                disabled: role.id !== ROL.SUPERADMIN
              }}
            />
  }
  const columns = [
    {accessorKey: "displayName", header: t('global.name')},
    {accessorKey: "mail", header: 'Email'},
    {accessorKey: "roles", header: t('global.rol'), Cell: cellRole },
    {accessorKey: "active", header: t('global.active'), Cell: cellStatus }
  ]

  const [buttonsView, setButtonsView] = useState([
    {
      text: t('users.viewAll'),
      mode: "all"
    },
    {
      text: t('users.viewMyStore'),
      mode: "myStore"
    }
  ])
  const [viewSelected, setViewSelected] = useState("all")

  useEffect(() => {
    goToGetRoles()
    goToGetAppUsers()
  }, [])

  const goToGetRoles = () => {
    getAppRoles().then(roles => {
      setRoles(transformToOption(roles))
      setButtonsView([...buttonsView, ...roles.map( rol => ({text: rol.name, mode: rol.name}))])
    })
  }

  const goToGetAppUsers = () => {
    setLoading(true)
    getAppUsers().then( users => {
      setAppUsers(users)
      setFilteredAppUsers(users)
    }).finally(() => setLoading(false))
  }

  const goToSearchGlobalUser = (mail) => {
    setLoadingGlobal(true)
    getGlobalUsers({ mail: mail, expand: "id,mail" }).then ( users => {
      setGlobalUsers(transformToOption(users, "mail"))
    }).finally(() => setLoadingGlobal(false))
  }

  const handleInputChange = (newValue) => {
    if(newValue.length > 3){
      goToSearchGlobalUser(newValue)
    }
  }

  const handleUserSelected = (user) => {
    setUserSelected(user)
    findUserSelected(user)
  }

  const findUserSelected = (user) => {
    setRoleSelected(null)
    const userInApp = appUsers.find( userApp => userApp.mail === user.label)
    if(userInApp){
      setRoleSelected(roles.filter( rol => rol.value === userInApp.roles[0].id))
    }
  }

  const goToUpdateUser = (params) => {
    setLoading(true)
    updateUser(params).then(() => {
      goToGetAppUsers()
      setGlobalUsers(null)
      setUserSelected(null)
      setRoleSelected(null)
      dispatch(createMsgToast({ body: params.successMsg, variant: "positive" }))
    })
  }

  const handleActive = (checked, user) => {
    setConfirmation({
      title: t('users.changeActive'),
      msg: t('users.changeActiveMsg'),
      button: t('global.save'),
      action: () => goToUpdateUser({
        userId:user.mail, 
        newRoleId: user.roles[0].id, 
        active: checked,
        successMsg: t("users.successUpdateActive")
      })
    })
  }

  const updateView = (mode) => {
    setViewSelected(mode)
    setFilteredAppUsers(
      appUsers.filter( user => {
        switch (mode) {
          case "all":
            return user
          case "myStore":
            return user.officeLocation === myStore.officeLocation
          default:
            return user.roles[0].name === mode
        }
      })
    )
  }

  return (
    <div className="container">
      <section className="container-header-button">
        <Text tagName="h2" headingSize="m">{t('users.title')}</Text>
      </section>

      <section>
        {role.id === ROL.SUPERADMIN &&
          <div className="row" style={{ alignItems: "center" }}>
            <Select
              formField={{
                className: "col-lg-6 col-md-12 col-sm-12"
              }}
              label={t('users.searchUserByMail')}
              isSearchable={true}
              onInputChange={handleInputChange}
              onChange={handleUserSelected}
              isLoading={loadingGlobal}
              dataId="user"
              options={globalUsers}
            />
            <Select
              formField={{
                className: "col-lg-4 col-md-8 col-sm-12"
              }}
              label={t('users.selectRole')}
              onChange={val => setRoleSelected(val)}
              value={roleSelected}
              isDisabled={!userSelected}
              dataId="role"
              options={roles}
            />
            <Button
              type="emphasised"
              text={t('global.save')}
              onClick={() => goToUpdateUser({ 
                userId: userSelected.label, 
                newRoleId: roleSelected.value,
                successMsg: t("users.successUpdateRole")
              })}
              className="col-lg-2 col-md-4 col-sm-12"
              disabled={!userSelected && !roleSelected}
            />
          </div>
        }

        {loading &&
          <Load />
        }
        {filteredAppUsers && !loading &&
        <>
          <article style={{ display: "flex", alignItems: "center", margin: "1rem 0"}}>
            <SSRIcon paths={eye}/>
            <Text >{filteredAppUsers.length}</Text>
            {buttonsView.map( btn =>
              <Button
                key={btn.text}
                type="secondary"
                small
                className="ml-1"
                text={btn.text}
                onClick={() => updateView(btn.mode)}
                disabled={viewSelected === btn.mode}
              />
            )}
          </article>
          <Table
            columns={columns}
            data={filteredAppUsers}
            enableSorting={true}
            enablePagination={false}
          />
          </>
        }
      </section>
      <ConfirmationModal confirmation={confirmation} />
      
    </div>
  )
}
