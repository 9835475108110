import { API_URLS } from '../configuration/apiConstants';
import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';


export const getAppUsers =  () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.USERS_BY_APP,
    api: process.env.REACT_APP_AUTH,
    originApp: true
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getAppRoles =  () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.ROLES_BY_APP(process.env.REACT_APP_ORIGIN_APP_ID),
    api: process.env.REACT_APP_AUTH,
    originApp: true
  }

  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response.roles)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getGlobalUsers = (params) => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.GLOBAL_USERS,
    api: process.env.REACT_APP_AUTH,
    originApp: true,
    params: {
      ...params,
      filterUsersWithoutEmployeeId: false,
      filterUsersWithoutOfficeLocation: false,
      filterUsersWithoutMail: true,
      filterUsersWithoutMailNickName: false
    }
  }

  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

  export async function updateUser({userId, newRoleId, active = true}){
    const fetchSettings = {
      method: "PUT",
      url: API_URLS.USER_UPDATE_ROLE,
      api: process.env.REACT_APP_AUTH,
      originApp: true,
      data: {
        userId,
        newRoleId,
        active
      },
      params: { register: true }
    }
    const dataFetch = new Promise ((resolve) => {
      apiFetch(fetchSettings).then((response)=>{
        resolve(response)
      })
    })
    return dataFetch
  }