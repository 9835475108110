import { useTranslation } from "react-i18next"
import Text from "../components/ingka/Text"
import Message from "../components/ingka/Message"
import style from "../assets/scss/mapMessagge.module.scss"
import arrowR from '@ingka/ssr-icon/paths/arrow-right'
import pencil from '@ingka/ssr-icon/paths/pencil'
import trash from '@ingka/ssr-icon/paths/trash-can'
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { createRoute } from "../store/globalSlice"
import { useState } from "react"
import Modal, { ModalFooter, ModalHeader, Prompt, Sheets } from "@ingka/modal";
import Button from "../components/ingka/Button"
import AddMap from "../components/global/AddMap"
import RadioGroup from '../components/ingka/RadioGroup';
import { deleteMap } from "../services/mapServices"
import { setAllMaps } from "../store/canvasSlice"
import { ROL } from "../configuration/globalConstants"

export default function Maps() {
  const [t] = useTranslation("global")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const maps = useSelector((state) => state.canvas.maps)
  const [modalActive, setModalActive] = useState(false)
  const [saveModal, setSaveModal] = useState(false)
  const [disabledSave, setDisabledSave] = useState(true)
  const [mapSelected, setMapSelected] = useState(null)
  const rol = useSelector((state) => state.global.rol)
  const [modalDelete, setModalDelete] = useState(false)
  const [deleteType, setDeleteType] = useState(true)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const goToMap = (map) => {
    navigate(`/map/${map.id}`)
    dispatch(createRoute({id:map.id, path:`/map/${map.id}`, back:"/maps"}))
  }

  const openModal = (map) => {
    setModalActive(!modalActive)
    setMapSelected(map)
  }

  const hideModalAndRefresh = () => {
    setModalActive(false)
    setSaveModal(false)
    setDisabledSave(true)
  }

  const handleDelete = (map) => {
    setMapSelected(map)
    setModalDelete(true)
  }

  const goToDeleteMap = () => {
    setLoadingDelete(true)
    const params = {
      mapId: mapSelected.id,
      resetOnlyMap: deleteType === "all" ? false : true
    }
    deleteMap(params, t('maps.deleteOk'))
    .then(() => {
      //update this map in store
      const indexMap = maps.findIndex(mapSto => mapSto.id === mapSelected.id)
      let mapWithChangesString = JSON.stringify(maps[indexMap])
      let mapWithChanges = JSON.parse(mapWithChangesString)
      mapWithChanges.image = null
      mapWithChanges.width = 0
      mapWithChanges.height = 0
      mapWithChanges.active = 0
      dispatch(setAllMaps([...maps.slice(0,indexMap), mapWithChanges, ...maps.slice(indexMap+1)]))
    })
    .finally(()=> {
      setLoadingDelete(false)
      setModalDelete(false)
    })
  }

  return (
    <div className="maps-container">
      <Text tagName="h2" headingSize="m">{t(`maps.title`)}</Text>
      {maps &&
        maps.map(map => 
          <Message 
            key={map.id}
            title={
              <div className={style.mapCard}>
                {map.image &&
                  <div className={style.image} style={{ backgroundImage: `url("data:image/png;base64,${map.image}")`}}></div>
                }
                
                <div className={style.content}>
                  <Text tagName="h3" headingSize="s">{map.name}</Text>
                  <Text tagName="p" bodySize="m">{map.description}</Text>
                  <div className={style.data}>
                    <Text tagName="p" bodySize="s">{map.creationDate.slice(0,10)}</Text>
                  </div>
                </div>
              </div>
            }
            variant={map.active === 0 ? "cautionary" : "informative"}
            actions={
              map.image !== null ? 
              [
                {
                  onClick: ()=>goToMap(map),
                  iconOnly: true,
                  ssrIcon: arrowR,
                  title: t('global.enter')
                },
                (rol.id === ROL.SUPERADMIN &&{
                  onClick: ()=> handleDelete(map),
                  iconOnly: true,
                  ssrIcon: trash,
                  title: t('global.delete')
                }),
              ]
              : 
              [{
                onClick: ()=> openModal(map),
                iconOnly: true,
                ssrIcon: pencil, 
                title: t('global.edit')
              },
            ]}
          />
        )
      }

      {/* MODAL FOR EDIT MAP */}
      <Modal
      visible={modalActive}
      handleCloseBtn={()=> {
        setModalActive(!modalActive)
        }}>
        <Sheets
          aria-label="Accessibility header for a modal"
          className="example-prompt-override"
          footer={
            <ModalFooter>
              <Button text={t("global.save")} 
                type="emphasised" 
                onClick={() => setSaveModal(true)} 
                disabled={disabledSave}
                loading={saveModal}/>
              <Button text={t("global.cancel")} />
            </ModalFooter>
            }
          header={<ModalHeader ariaCloseTxt="Close button text" title={t(`map.putMapTitle`)}/>}
        >
          <div className="container">
          
            <AddMap
              disabledSave={(dis) => setDisabledSave(dis)}
              save={saveModal}
              type="put"
              map={mapSelected}
              hideModalAndRefresh={hideModalAndRefresh}
            />
          </div>
        </Sheets>
      </Modal>

      {/* MODAL FOR DELETE MAP */}
      <Modal
        visible={modalDelete}
        focusLockProps={{
          disabled: false
        }}
        handleCloseBtn={()=> setModalDelete(!modalDelete)}
        data-id="confirmationDeleteModal"
      >
        <Prompt
          footer={
            <ModalFooter>
              <Button 
              text={t('global.cancel')} 
              type="secondary" 
              data-id="cancelDelete"
              />
              <Button 
              text={t('global.delete')} 
              type="emphasised"
              data-id="confirmDelete"
              onClick={() => goToDeleteMap()}
              loading={loadingDelete}
              />
            </ModalFooter>
          }
          header={<ModalHeader />}
          title={t('maps.deleteMap')}
        >
          <RadioGroup 
            radio={{
              list: [
                {
                  id: 'onlyImage',
                  label: t('maps.deleteOnlyImage'),
                  value: "onlyImage",              
                  defaultChecked: true,
                  onChange: (e)=> setDeleteType(e.target.value),
                },
                {
                  id: 'all',
                  label: t('maps.deleteAllMap'),
                  value: "all",
                  defaultChecked: false,
                  onChange: (e)=> setDeleteType(e.target.value),
                },
              ],
              name: t('maps.optionsDelete')
            }}
          />
          <p>
            {t('maps.deleteMapMsg')}
          </p>
        </Prompt>
      </Modal>
    </div>
  )
}
