import { useEffect, useState } from "react";
import Text from "../ingka/Text";
import style from "./progress.module.scss";

export default function ProgressBar({made, total, title}) {
  const[percent, setPercent] = useState(0)
  useEffect(() => {
    if(made && total){
      setPercent(Math.round((made*100)/total))
    }
  },[made, total])

  return (
    <div>
      <div className={style.data}>
        <Text tagName="h4" bodySize="l">{title}</Text>
        <Text tagName="h5" bodySize="m">{`${made} / ${total}`}</Text>
      </div>
      <div className={style.bar}>
        <div className={style.filler} style={{width:`${percent <= 100 ? percent : 100}%`}}>
          <span className={`${style.label} ${percent >= 5 ? style.left : style.right}`}>{`${percent}%`}</span>
        </div>
      </div>
    </div>
    
  )
}