/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import Text from "../components/ingka/Text";
import Filter from '../components/global/Filter';
import { getMotionsDynamic } from "../services/viewingMotionsServices";
import { useEffect, useState } from "react";
import Load from "../components/ingka/Load";
import MyTab from "../components/ingka/MyTab";
import CanvasMotions from "../components/global/CanvasMotions";
import { useSelector } from "react-redux";
import { PercentToPixel, PercentToPixelForConcatArray } from "../utils/pointsConverter";
import { CalculateArea, CalculateBackgroundPosition, CalculateBackgroundSize, CalculateCanvasSize } from "../utils/calculateCanvasSize";
import { getMap, getSection } from "../services/mapServices";
import { useNavigate } from "react-router-dom";
import { CalculateMotionsData, CalculateStatistics, amountActionPerAction } from "../utils/calculateAny";
import Table from "../components/global/Table";
import Button from "../components/ingka/Button";
import * as XLSX from "xlsx";

export default function Motions() {
  const [t] = useTranslation("global")
  const navigate = useNavigate()

  const MAP_LEVEL = 0
  const SECTION_LEVEL=1
  const SUBSECTION_LEVEL=2
  const trafficRadius = 20
  const maps = useSelector((state) => state.canvas.maps)
  const [loading, setLoading] = useState(false)
  const [level, setLevel] = useState(null)
  const [motionsRaw, setMotionsRaw] = useState(null)
  const [motions, setMotions] = useState(null)
  const [map, setMap] = useState(null)
  const[rawResponse, setRawResponse] = useState(null)
  const [area, setArea] = useState(null)
  const[canvasSize, setCanvasSize] = useState(null)
  const[backgroundSize, setBackgroundSize] = useState(null)
  const[backgroundPosition, setBackgroundPosition] = useState(null)
  const [mode, setMode] = useState("flow")
  const [noMotions, setNoMotions] = useState(false)
  const [statistics, setStatistics] = useState(null)
  const [options, setOptions] = useState(null)
  const [motionsData, setMotionsData] = useState(null)
  const [query, setQuery] = useState(null)
  const [actionOptions, setActionOptions] = useState(null)


  const goToGetMovements = (query) => {
    //console.log("Query", query)
    query.onlyFinalized = true
    setLevel(query.level)
    setLoading(true)
    //reset map and motions 
    setMap(null)
    setMotions(null)
    setArea(null)
    setQuery(query)

    getMotionsDynamic(query).then(motions => {
      //console.log(motions)
      if(motions.length === 0){
        setNoMotions(true)
        setLoading(false)
      }else{
        setNoMotions(false)
        setMotionsRaw(motions)
        //console.log("Motions", motions)
        const theMap = maps.find(map => map.id === query.mapId)
        if(query.level === MAP_LEVEL){
          getMap({mapId: query.mapId})
          .then(map => {
            if(theMap.image !== undefined){
              map.image = theMap.image
              map.level = MAP_LEVEL
              //map.addons = map.markers.map(marker => ({...marker, isMarker:true}))
              setMap(map)
              setCanvasSize(CalculateCanvasSize(map.width, map.height))
            }else{
              navigate('dashboard')
            }
            
          })
          setCanvasSize(CalculateCanvasSize(theMap.width, theMap.height))
          setBackgroundPosition("center")
          setBackgroundSize("contain")
        } else {
          getSection(query.sectionId)
        .then( response => {
          let section
          let versionResponse = response
          //console.log(response)
          if(response.versions.length > 0 && query.versionId !== response.modificationDate){
            const version = response.versions.find(el => el.date === query.versionId)
            versionResponse.width = version.width
            versionResponse.height = version.height
            versionResponse.point = version.point
          }
          
          setRawResponse({...versionResponse})
            if(query.level === SUBSECTION_LEVEL){
              section = response.polygons.find(poly => poly.id === query.subsectionId)
              section.width = response.width * (section.width/100)
              section.height = response.height * (section.height/100)
              section.points = JSON.parse(section.point)
              section.points.forEach(el => {
                el[0] = response.width * (el[0]/100)
                el[1] = response.height * (el[1]/100)
              })
              //console.log("subsection",section)
            }else{
              section = response
              section.points = JSON.parse(section.point)
            }

            if(theMap.image !== undefined){
              section.image = theMap.image
            }else{
              //if there is no map image, return to maps 
              navigate('dashboard')
            }
            
            const canvasSizePx = PercentToPixel([[section.width, section.height]],theMap.width, theMap.height)
            setCanvasSize(CalculateCanvasSize(canvasSizePx[0][0], canvasSizePx[0][1]))
            setMap(section)
          })
        }
      }
    })
  }

  useEffect(() => {
    if(canvasSize && level !== MAP_LEVEL){
      setBackgroundSize(CalculateBackgroundSize(canvasSize.w, canvasSize.h, map.width, map.height))
    }
    
  }, [canvasSize])

  useEffect(() => {
    if(backgroundSize && level !== MAP_LEVEL){
      const points = JSON.parse(rawResponse.point)
      const position = CalculateBackgroundPosition(points, backgroundSize.w, backgroundSize.h)
      if(level === SECTION_LEVEL){
        setBackgroundPosition(position)
        setArea(CalculateArea(points, backgroundSize.w, backgroundSize.h,position))
      }
      else if(level === SUBSECTION_LEVEL){
        const subPosition = CalculateBackgroundPosition(map.points, backgroundSize.w, backgroundSize.h)
        position.x = position.x + subPosition.x
        position.y = position.y + subPosition.y
        setArea(CalculateArea(map.points, backgroundSize.w, backgroundSize.h,subPosition))
        setBackgroundPosition(position)
      }
    }
  },[backgroundSize])

  useEffect(() => {
    if(motionsRaw && canvasSize && map){
      let times = []
      let groups = []
      let ages = []
      let genders = []
      let nationalities = []
      let tools = []
      let actions = []
      const motionsPx = motionsRaw.map(motion => {
        //use the loop for the statistics
        times.push(motion.trackingTime)
        groups.push(motion.demographicGroup)
        ages.push(motion.ageRange)
        genders.push(motion.gender)
        nationalities.push(motion.nationality)
        tools.push(motion.tool)
        
        //motion for canvas
        const path = PercentToPixelForConcatArray(JSON.parse(motion.path), canvasSize.w, canvasSize.h)
        const act = motion.motionActions.map(el => {
          actions.push(el.action.id)
          const point = PercentToPixelForConcatArray(JSON.parse(el.point), canvasSize.w, canvasSize.h)
          const icon = el.action.required ? el.action.icon.replaceAll("[", "").replaceAll("]", "").split(",")
          : JSON.parse(el.action.icon)
          return {point: point, iterationValue: el.iterationValue, ...el.action, icon:icon}
        })
        return {path: path, actions: act}
      })

      setActionOptions(amountActionPerAction(options, actions))
      setMotions(motionsPx)
      //console.log(options, times, groups, ages, genders, nationalities, tools, actions)
      setStatistics(CalculateStatistics(options, times, groups, ages, genders, nationalities, tools, actions))

      //console.log("MAP",map)
      setMotionsData(CalculateMotionsData(motionsPx, canvasSize, trafficRadius, map))
      setLoading(false)
    }
  },[motionsRaw,canvasSize,map])

  const downloadXls = () => {
    // create workbook and worksheet
    console.log(motionsRaw, options, query)
    const motionsFormat = motionsRaw.map( motion => {
      return {
        id: motion.id,
        mapId: query.mapId,
        mapName: maps.find( map => map.id === query.mapId).name,
        ...(query.sectionId ? {sectionId : motion.section.id, sectionName: motion.section.name} : {}),
        ...(query.subsectionId ? {sectionId : motion.section.id, sectionName: motion.section.name} : {}),
        creationDate: motion.creationDate,
        modificationDate: motion.modificationDate,
        actions: motion.motionActions.map( act => {
          return act.action.title
        }).toString(),
        stateId: motion.state.id,
        stateName: motion.state.value,
        storeId: motion.storeId,
        trackingTimeSeconds: motion.trackingTime,
        user: motion.userId,
        version: motion.versionId,
        ageRangeId: motion.ageRange,
        ageRangeName: motion.ageRange ? options.ageOptions.find( el => el.value == motion.ageRange).label : "",
        demographicGroupId: motion.demographicGroup,
        demographicGroupName: motion.demographicGroup ? options.groupOptions.find( el => el.value == motion.demographicGroup).label : "",
        genderId: motion.gender,
        genderName: motion.gender ? options.genderOptions.find( el => el.value == motion.gender).label : "",
        nationalityId: motion.nationality,
        nationalityName: motion.nationality ? options.nationalityOptions.find( el => el.value == motion.nationality).label : "",
        toolId: motion.tool,
        toolName: motion.tool ? options.toolsOptions.find( el => el.value == motion.tool).label : ""
      }
    })
    console.log(motionsFormat)
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(motionsFormat);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Motions");

    // customize header names
    /* XLSX.utils.sheet_add_aoa(worksheet, [
      ["Motion ID", "Motion Name", "Motion Category"],
    ]); */

    XLSX.writeFile(workbook, "Motions.xlsx", { compression: true });
  }

  return (
    <div>
      <Text tagName="h2" headingSize="m">{t(`motions.title`)}</Text>
      <Filter pushQuery={goToGetMovements} pushOptions={(data) => setOptions(data)}/>
      <div>
        {loading &&
          <Load />
        }
        {!loading && motions && map &&
          <MyTab 
          tabs={[
            {key: 1, text: t('motions.panelFlow'), tabPanelId: "flow"},
            {key: 2, text: t('motions.panelTraffic'), tabPanelId: "traffic"},
            {key: 3, text: t('motions.panelHeatMap'), tabPanelId: "heatMap"},
            {key: 4, text: t('motions.panelActions'), tabPanelId: "actions"}
          ]}
          tabPanels={[]} //I do not use tabPanels for the Canvas because it would paint the same element multiple times and it is very heavy
          onTabChanged={(mode) => setMode(mode)}
        />
        }
        {noMotions &&
          <Text tagName="p" bodySize="xl">{t('motions.noMotions')}</Text>
        }
        {!loading && motions && map && backgroundPosition && backgroundSize && motionsData &&
          <div>
            <CanvasMotions 
              mode={mode}
              map={map}
              motions={motions}
              canvasSize={canvasSize}
              backgroundPosition={level === MAP_LEVEL ? "center" : `-${backgroundPosition.x}px -${backgroundPosition.y}px`}
              backgroundSize={level === MAP_LEVEL ? "contain" : `${backgroundSize.w}px ${backgroundSize.h}px`}
              area={area}
              actionOpt={actionOptions}
              heatData={motionsData.heat}
              trafficData={motionsData.traffic}
              trafficRadius={trafficRadius}
            />
            <div className="mt-2 mb-3">
              <Text tagName="h3" headingSize="m">{t('motions.statisticsTitle')}</Text>
              

              <Table 
                columns={[
                  {accessorKey: "label", header: t('global.concept')},
                  {accessorKey: "value", header: t('global.value')}
                ]} 
                data={[
                  {label: t('motions.totalMotions'), value: statistics.totalMotions},
                  {label: t('motions.averageTime'), value: statistics.averageTime},
                  {label: t('motions.maxTime'), value: statistics.maxTime},
                  {label: t('motions.minTime'), value: statistics.minTime},
                  {label: t('motions.popularGroup'), value: statistics.popularGroup},
                  {label: t('motions.popularGender'), value: statistics.popularGender},
                  {label: t('motions.popularAge'), value: statistics.popularAge},
                  {label: t('motions.popularNationality'), value: statistics.popularNationality},
                  {label: t('motions.popularTool'), value: statistics.popularTool},
                  {label: t('motions.totalActions'), value: statistics.totalActions},
                  {label: t('motions.averageActionsMap'), value: statistics.averageActionsMap},
                  {label: t('motions.popularAction'), value: statistics.popularAction},
                  ...(map.level === 0 ? [{label: t('motions.shortcutHorizontal'), value: motionsData.shortcut.horizontal}] : []),
                  ...(map.level === 0 ? [{label: t('motions.shortcutVertical'), value: motionsData.shortcut.vertical}] : []),
                  ...(map.level === 0 ? [{label: t('motions.shortcuts'), value: motionsData.shortcut.horizontal + motionsData.shortcut.vertical}] : []),
                  ...(map.level === 0 ? [{label: t('motions.motionWidthShortcut'), value: motionsData.shortcut.motionsWidthShortcut.length}] : []),
                  ...(map.level === 0 ? [{label: t('motions.oppositeFlow'), value: motionsData.oppositeFlow.length}] : [])
                ]}
                enableSorting={false}
                enablePagination={false}
              />
              <Button 
                text={t("motions.downloadXls")}
                className="mt-2"
                onClick={downloadXls}
              />
            </div>
            
          </div>
        }
      </div>
    </div>
  )
}
