import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: localStorage.getItem("language"),
  login: !!localStorage.getItem("login"),
  token: localStorage.getItem("token") || null,
  resfreshToken: localStorage.getItem("refreshToken") || null,
  user: localStorage.getItem("user"),
  userName: localStorage.getItem("userName"),
  email: localStorage.getItem("email"),
  rol: JSON.parse(localStorage.getItem("rol")),
  toasts: [],
  path: localStorage.getItem("path"),
  page: localStorage.getItem("page"),
  route: JSON.parse(localStorage.getItem("route")),
  motion: JSON.parse(localStorage.getItem("motion")),
  myStore: JSON.parse(localStorage.getItem("myStore")),
  hfbs: JSON.parse(localStorage.getItem("hfbs"))
};

export const globalSlice = createSlice(
  {
  name: "global",
  initialState,
  reducers: {
    
    doToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem("token", state.token)
    },
    doRefreshToken: (state, action) => {
      state.resfreshToken = action.payload;
      localStorage.setItem("refreshToken", state.resfreshToken)
    },
    doLogin: (state,action) => {
      state.user = action.payload.user
      state.userName = action.payload.userName
      state.rol = action.payload.rol
      state.email = action.payload.email
      state.myStore = action.payload.myStore
      localStorage.setItem("user", state.user)
      localStorage.setItem("userName", state.userName)
      localStorage.setItem("email", state.email)
      localStorage.setItem("rol", JSON.stringify(state.rol))
      localStorage.setItem("myStore", JSON.stringify(state.myStore))
    },

    doLoginFinalize: (state,action)=>{
      state.login = action.payload
      localStorage.setItem("login", state.login)
    },

    doLogout: () => {
      localStorage.clear()
      return {
        language: null,
        login: false,
        token: null,
        user: null,
        userName: null,
        email: null,
        rol: null,
        toasts: [],
        path: null,
        page: null,
        route: null,
        motion: null,
        myStore: null,
        hfbs: null
      }
    },

    changeLang: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("language", state.language)
    },

    createMsgToast: (state, action) => {
      state.toasts = [...state.toasts, action.payload]
    },
    deleteMsgToast: (state, action) => {
      state.toasts = state.toasts.slice(0, action.payload)
    },

    updatePath: (state, action) =>{
      state.path = action.payload
      localStorage.setItem("path", state.path)
    },

    updatePage: (state, action) =>{
      state.page = action.payload
      localStorage.setItem("page", state.page)
    },
    createRoute: (state, action) =>{
      state.route = [action.payload]
      localStorage.setItem("route", JSON.stringify(state.route))
    },

    updateRoute: (state, action) =>{
      state.route = [...state.route, action.payload]
      localStorage.setItem("route", JSON.stringify(state.route))
    },
    clearRoute: (state, action) =>{
      if(action.payload === null){
        state.route = action.payload
        localStorage.setItem("route", state.route)
      }else{
        state.route = [...state.route.filter(rou => rou.title === action.payload)]
        localStorage.setItem("route", JSON.stringify(state.route))
      }
      
    },
    setMotion: (state, action) => {
      state.motion = action.payload
      localStorage.setItem("motion", JSON.stringify(state.motion))
    },
    setHfbs: (state, action) => {
      state.hfbs = action.payload
      localStorage.setItem("hfbs", JSON.stringify(state.hfbs))
    },
    setMyStore: (state, action) => {
      state.myStore = action.payload
      localStorage.setItem("myStore", JSON.stringify(state.myStore))
    }
  },
});

export const { 
  doToken,
  doRefreshToken,
  changeLang, 
  doLogin, 
  doLoginFinalize,
  doLogout,
  createMsgToast,
  deleteMsgToast,
  updatePath,
  updatePage,
  createRoute,
  updateRoute,
  clearRoute,
  setMotion,
  setHfbs,
  setMyStore
} = globalSlice.actions;
export default globalSlice.reducer;