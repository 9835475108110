/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import Input from "../ingka/Input"
import { useTranslation } from "react-i18next"
import Button from "@ingka/button"
import upload from "@ingka/ssr-icon/paths/arrow-up-from-base"
import { updateMap } from "../../services/mapServices"
import { useDispatch, useSelector } from "react-redux"
import Text from "../ingka/Text"
import { createMsgToast } from "../../store/globalSlice"
import { setAllMaps } from "../../store/canvasSlice"
import imageCompression from 'browser-image-compression';

export default function AddMap({type, map, disabledSave, save, hideModalAndRefresh}) {
  const [t] = useTranslation("global")
  const dispatch = useDispatch()

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [fileSize, setFileSize] = useState({})
  const [loadingUpload, setLoadingUpload] = useState(false)
  const maps = useSelector((state) => state.canvas.maps)
  const [motionGoal, setMotionGoal] = useState(10)
  const ONE_MB = 1000000
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 2000,
    useWebWorker: true,
    fileType: "image/webp",
    alwaysKeepResolution: true
  }

  const saveMap = () => {
    let data = new FormData()
    
    if(file)data.append('image', file)
    data.append('map', JSON.stringify({
      name: name,
      description: description,
      active: file || map.image ? 1 : 0,
      width: fileSize.width,
      height: fileSize.height,
      motionGoal: motionGoal
    }))
    updateMap(map.id, data, t('maps.updateMapOk'))
    .then((response) => {
      //update this map in store
      const indexMap = maps.findIndex(mapSto => mapSto.id === map.id)
      let mapWithChangesString = JSON.stringify(maps[indexMap])
      let mapWithChanges = JSON.parse(mapWithChangesString)
      mapWithChanges.name = response.name
      mapWithChanges.description = response.description
      mapWithChanges.active = response.active
      mapWithChanges.image = response.image
      mapWithChanges.width = response.width
      mapWithChanges.height = response.height
      mapWithChanges.motionGoal = response.motionGoal
      dispatch(setAllMaps([...maps.slice(0,indexMap), mapWithChanges, ...maps.slice(indexMap+1)]))
    })
    .finally(()=> {
      hideModalAndRefresh()
    })
  }

  useEffect(() => {
    if(save){
      saveMap()
    }
  },[save])

  useEffect(()=>{
    setName(map.name)
    setDescription(map.description)
    if(map.image !== null){
      setMotionGoal(map.motionGoal)
    }
  },[])


  const uploadFile = ()=>{
    let el = window._protected_reference = document.createElement("INPUT");
    el.type = "file";
    el.accept = "image/*";
    el.addEventListener('change', function() {
      if (el.files.length) {
        const file = el.files[0]
        getSizeImg(file).then( size => {
          const fileSizeRaw = size
          if(file.size > ONE_MB){
            setLoadingUpload(true)
            imageCompression(file, options)
              .then((compressedFile) => {
                  getSizeImg(compressedFile).then( compressFileSize => {
                    if(fileSizeMatch(fileSizeRaw, compressFileSize)){
                      setFileSize(compressFileSize)
                      setFile(compressedFile)
                      setFileName(compressedFile.name)
                    } else {
                      dispatch(createMsgToast({body: t("maps.addMap.noMatchImage"), title:"Error", variant:"negative"}))
                      setFile(null)
                      setFileSize({})
                      setFileName(null)
                    }
                  })
              })
              .catch(function () {
                dispatch(createMsgToast({body: t("maps.addMap.noResizeImage"), title:"Error", variant:"negative"}))
              })
              .finally(()=> setLoadingUpload(false))
          } else {
            setFileSize(fileSizeRaw)
            setFile(file)
            setFileName(file.name)
          }
        })
        
      }
      el = window._protected_reference = undefined;
    });
    el.click();
  }

  const getSizeImg = (file) => {
    const getSize = new Promise((resolve) => {
      const img = new Image()
      img.src = URL.createObjectURL(file);
      img.onload = function(){
        resolve({width:this.width, height:this.height})
      }
    })
    return getSize
  }

  const fileSizeMatch = (fileSize, compressedFileSize) => {
    if(map.image !== null){ //validate if is image and the image is the same width and height
      if((compressedFileSize?.width !== map.width && compressedFileSize?.height !== map.height) || (fileSize.width !== map.width && fileSize.height !== map.height)){ 
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if(name.length > 2 && description.length > 4){
      disabledSave(false)
    }
  },[name, file, description])

  return (
    <div className="row">      
      <Input 
        formField={{
          className: type === "post" ? "col-lg-6 col-md-12 col-sm-12" : "col-12"
        }}
        input={{
          label: t("maps.addMap.labelTitle"),
          value: name,
          onChange: e => setName(e.target.value),
          disabled:true
        }}
      />

      <Input 
        formField={{
          className: "col-12"
        }}
        input={{
          label: t("global.description")+"*",
          value: description,
          onChange: e => setDescription(e.target.value)
        }}
      />

      
      <Input 
        formField={{
          className: "col-12"
        }}
        input={{
          label: t("maps.addMap.motionGoal"),
          type:"number",
          value: motionGoal,
          onChange: e => setMotionGoal(e.target.value)
        }}
      />
    
      <Button
        type="secondary" 
        text={fileName === null ? t("global.uploadImage") : fileName}
        ssrIcon={upload} 
        onClick={uploadFile}
        className={type === "post" ? "col-lg-3 col-md-6 col-sm-6" : "col-12"}
        loading={loadingUpload}
      />
      {map.image !== null &&
        <>
          <Text tagName="p" bodySize="s" className="mt-2">{t('maps.addMap.actualSize')+": "+map.width + " x " + map.height}</Text>
          <Text tagName="p" bodySize="s" className="">{t('maps.addMap.putMapInstructions')}</Text>
        </>
      }
    </div>
  )
}
