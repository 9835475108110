import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';


export const getSaveOptions =  () => {
  const fetchSettings = {
    method: "GET",
    url: 'itemsToMotion',
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const saveMapMotion =  (data, motionId=false) => {
  const myStore = JSON.parse(window.localStorage.getItem('myStore'))
  const user = window.localStorage.getItem('user')
  const theData = {
    ...data,
    userId: user,
    storeId:myStore.id
  }
  const fetchSettings = {
    method: !motionId ? "POST" : "PUT",
    url: !motionId ? 'motion' : `motion/${motionId}`,
    data: theData
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}