/* eslint-disable react-hooks/exhaustive-deps */
import "../assets/scss/login.scss";
import Text from "../components/ingka/Text";
import { useTranslation } from "react-i18next";
import Button from "../components/ingka/Button";
import { useDispatch, useSelector } from "react-redux";

import { changeLang, doLogin, doLoginFinalize, doRefreshToken, doToken } from "../store/globalSlice";
import Load from '../components/ingka/Load';
import { useEffect, useState } from "react";
import { authSSO, getMaintenance } from "../services/loginSevices";
import { useSearchParams } from "react-router-dom";

export default function Login() {
  const [t] = useTranslation("global")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const token = useSelector((state) => state.global.token)
  const myStore = useSelector((state) => state.global.myStore)
  const userName = useSelector((state) => state.global.userName)
  const [year, setYear] = useState("20**")
  const [maintenance, setMaintenance] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(()=>{
    calculateYear()
    lookAtMaintenance()
  },[])

  const calculateYear = () => {
    const date = new Date()
    setYear(date.getFullYear())
  }

  const lookAtMaintenance = () => {
    getMaintenance().then( inMaintenance => {
      if(inMaintenance){
        setMaintenance(true)
      } else {
        goToGetCredentials()
      }
    }).finally(setLoading(false))
  }

  const goToSSO = () => {
    window.location.href = "https://login.microsoftonline.com/720b637a-655a-40cf-816a-f22f40755c2c/oauth2/v2.0/authorize?client_id="+
    process.env.REACT_APP_MICROSOFT_CLIENT_ID+"&redirect_uri="+
    process.env.REACT_APP_MICROSOFT_REDIRECT_URI+"&scope="+
    process.env.REACT_APP_MICROSOFT_SCOPE
    +"&response_type=code&response_mode=query"
  }

  const goToGetCredentials = () =>{
    const values = window.location.search
    //There is no token but there is a code in slug
    if((values && token === "undefined") || (values && token === null) ){
      const urlParams = new URLSearchParams(values)
      const code = urlParams.get('code');
      if(code){
        setLoading(true)
        authSSO({
          code: code, 
          redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECT_URI,
          originApp: process.env.REACT_APP_ORIGIN_APP
        })
        .then((response) => {
          dispatch(doLogin({
            user: response.mailNickName,
            userName: response.displayName,
            rol: {id: response.rolId, name: response.rolName},
            email: response.mail,
            myStore: {
              id: response.officeId, 
              name: response.officeName,
              officeLocation: response.officeLocation,
            }
          }))
          dispatch(doToken(response.accessToken))
          dispatch(doRefreshToken(response.refreshToken))
          dispatch(changeLang(response.preferredLanguage))
        })
        .catch(() => {
          if (searchParams.has('code')) {
            searchParams.delete('code');
            setSearchParams(searchParams);
          }
          setLoading(false)
        })
      }
    }
  }

  useEffect(() => {
    if(myStore && userName){
      dispatch(doLoginFinalize(true))
      //It is auto redirected to dashboard by the router when setting login to true
    }
  }, [myStore, userName])


  return (
    <div className="login">
      <div className="container">
        <div className="brand">
          <Text
            tagName="h1"  
            className="title">
              <span>Customer </span>
              <span>Flow</span>
          </Text>
          <Text
            tagName="h2" 
            headingSize="s"
            className="subtitle">
              {t("login.subtitle")}
          </Text>
        </div>
      </div>
      <div className="form">
          <div className="container">
            {!loading &&
            <div className="form-container">
              {!maintenance &&
                <Button
                  data-id="login-btn"
                  type="emphasised"
                  text={t("login.buttonContinue")}
                  onClick={goToSSO}
                ></Button>
              }
              {maintenance &&
                <div>
                  <Text tagName="h3" headingSize="l">{t('login.appInMaintenanceTitle')}</Text>
                  <Text tagName="p" bodySize="xl">{t('login.appInMaintenanceMsg')}</Text>
                </div>
              }
            </div>
            }
            {loading &&
            <div className="mt-3">
              <Load/>
            </div>
            }
            
          </div>
        </div>
        <div className="copy">
        <Text
            tagName="span" 
            bodySize="s">
              @Digital Spain 2021-{year}
          </Text>
          
        </div>
    </div>
  )
}
