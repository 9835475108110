
import { apiFetch, apiFetchIkea } from '../configuration/apiFetch';
import { addToMaps, setAllMaps } from '../store/canvasSlice';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';

export const getListMaps =  () => {
  const myStore = JSON.parse(window.localStorage.getItem('myStore'))
  const fetchSettings = {
    method: "GET",
    url: `maps/${myStore.id}`,
    params: {
      showImage: true
    }
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      store.dispatch(setAllMaps(response))
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const newMap =  (data, successMsg) => {
  const fetchSettings = {
    method: "POST",
    url: 'map',
    data,
    contentType: "multipart/form-data"
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
      store.dispatch(addToMaps(response))
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getMap = ({mapId}) => {
  const fetchSettings = {
    method: "GET",
    url: `map/${mapId}`,
    params: {
      showImage: false
    }
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const updateMap =  (mapId,data, successMsg) => {
  const fetchSettings = {
    method: "PUT",
    url: `map/${mapId}`,
    data,
    contentType: "multipart/form-data"
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const changeMapRoute = (data, msgs, route) => {
  //console.log(data, route)
  let fetchSettings
  let successMsg
  if(route === null){
    fetchSettings = {
      method: "POST",
      url: 'route',
      data
    }
    successMsg = msgs.post
  }else if(route !== null && data.point !== "[]"){
    fetchSettings = {
      method: "PUT",
      url: `route/${route.id}`,
      data
    }
    successMsg = msgs.put
  }else if(route !== null && data.point === "[]"){
    fetchSettings = {
      method: "DELETE",
      url: `route/${route.id}`
    }
    successMsg = msgs.del
  }
  
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response || null)
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getMarkers = (mode, level) => {
  const fetchSettings = {
    method: "GET",
    url: mode+"s/"+level
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const saveAddon = (type, mode, addon, addonEdit, points, mapId, level) => {
  const data = {
    ...addon,
    point: points,
    ...(level === 0 ? {mapId: mapId} : {sectionId: mapId})
  }
  const fetchSettings = {
    method: type,
    url: type === "POST" ? mode : mode + `/${addonEdit.id}`,
    data,
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const deleteAddon = (mode, id, successMsg) => {
  const fetchSettings = {
    method: "DELETE",
    url: `${mode}/${id}`
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then(()=>{
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
      resolve()
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getSectionsTypes =  (mode) => {
  const fetchSettings = {
    method: "GET",
    url: mode === "section" ? 'sectionsTypes' : 'subSections'
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getSubsectionsTypes =  (type, fatherValue) => {
  const PA_OR_HFB = "1"
  const fetchSettings = {
    method: "GET",
    url: 'subOptions',
    params: {
      subOptions: type.nameSubOption,
      ...(type.value === PA_OR_HFB ? {value: fatherValue} : {})
    }
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const savePolygon =  (type, data, polygon) => {
  const fetchSettings = {
    method: type,
    url: type === "POST" ? "section" :`section/${polygon.id}`,
    data
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

//SECTION VIEW
export const getSection = (sectionId) => {
  const fetchSettings = {
    method: "GET",
    url: `section/${sectionId}`,
    params: {
      showImage: false
    }
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getIkeaProducts = (ref) => {
  const lang = window.localStorage.getItem('language')
  const fetchSettings = {
    method: "GET",
    url: 'salesitem/communications/RU/ES',
    params: {
      languageCode: lang, 
      itemNos: ref
    }
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetchIkea(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const deleteMap = (params, successMsg) => {
  const fetchSettings = {
    method: "PUT",
    url: 'map-reset',
    params
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response) throw response
      store.dispatch(createMsgToast({body: successMsg, variant:"positive"}))
      resolve()
    }).catch((response)=>{
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}