import { API_URLS } from '../configuration/apiConstants';
import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';


export const authSSO = (params) => {
  const fetchSettings = {
    method: "GET",
    url: 'authSSO',
    params,
    headerParams: false,
    api: process.env.REACT_APP_AUTH
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      reject(new Error('Error'))
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getMaintenance = () => {
  const fetchSettings = {
    method: "GET",
    url: API_URLS.MAINTENANCE,
    originApp: true,
    api: process.env.REACT_APP_AUTH
  }
  const dataFetch = new Promise ((resolve) => {
    apiFetch(fetchSettings).then((response)=>{
      resolve(response.maintenance)
    })
  })
  return dataFetch
}