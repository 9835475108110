import axios from "axios";
import { store } from "../store/store";
import { doLogout, doRefreshToken, doToken } from "../store/globalSlice";
import t from "../languages/es/global.json"
import { CODE_AUTH_EXPIRED, CODE_MULTI_FACTOR_AUTH } from "./globalConstants";

async function refreshAuthToken(lang) {
  const refreshToken = store.getState().global.resfreshToken
  const header = {
    "Content-Type": "application/json",
    'Authorization': refreshToken ? 'Bearer ' + refreshToken : '',
    "originApp": process.env.REACT_APP_ORIGIN_APP,
    "Accept-Language": lang
  }
  const redirectUri = process.env.REACT_APP_MICROSOFT_REDIRECT_URI
  const originApp = process.env.REACT_APP_ORIGIN_APP

  const response = await axios.get(`${process.env.REACT_APP_AUTH}/refresh?redirectUri=${redirectUri}&originApp=${originApp}`,{headers: header})
  
  return { token: response.data.accessToken, refreshToken: response.data.resfreshToken }
}

export async function apiFetch({
  method = "GET",
  url,
  data,
  params,
  contentType = "application/json",
  headerParams = true,
  api = process.env.REACT_APP_API,
  originApp = false,
  customError404 = false
}) {
  const lang = store.getState().global.lang
  const token = store.getState().global.token
  const user = store.getState().global.user

  const header = {
    "Content-Type": contentType,
    ...(headerParams ? {
      'Authorization': token ? 'Bearer ' + token : '',
      ...(originApp ? { "originApp": process.env.REACT_APP_ORIGIN_APP } : { 'originUser': user?.nickName }),
      "Accept-Language": lang
    } : {})
  }

  try {
    let response
    response = await axios({
      method: method,
      url: api + url,
      ...(params && { params }),
      ...(data && { data }),
      headers: header,
    });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Token expired, try to refresh
      try {
        const newTokens = await refreshAuthToken(lang)
        // Update store status with new tokens
        store.dispatch(doToken(newTokens.token));
        store.dispatch(doRefreshToken(newTokens.refreshToken))
        
        // Retry the original request
        return apiFetch({ method, url, data, params, contentType, headerParams, api, originApp });
      } catch (refreshError) {
        // Error handling when refreshing the token
        store.dispatch(doLogout())
        const axiosError = {
          response: refreshError.name,
          message: refreshError.message
        }
        return axiosError
      }
    }

    if (error.code === "ERR_NETWORK" || error.code === 403) {
      store.dispatch(doLogout())
      const axiosError = {
        response: error.name,
        message: error.message
      }
      return axiosError
    } else if (error.response.status === 422 || error.response.status === 401) {
      store.dispatch(doLogout())
      const axiosError = {
        response: error.code,
        message: error.response.data.message
      }
      return axiosError
    } else if (error.response.status === 404) {
      window.location.href = "/not-found"

    } else if (error.response.status === 400 && data.message.includes(CODE_MULTI_FACTOR_AUTH)) {
      const axiosError = {
        response: error.code,
        message: t.error.multiFactorAuth
      }
      return axiosError
    }
    else if (error.response.status === 400 && data.message.includes(CODE_AUTH_EXPIRED)) {
      const axiosError = {
        response: error.code,
        message: t.error.codeAuthExpired
      }
      const cleanUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({}, document.title, cleanUrl);
      return axiosError
    } else {
      return error
    }

  }
}


export async function apiFetchIkea({ method, url, data, body = false, contentType = "application/json" }) {
  const header = {
    "Content-Type": contentType,
    "x-client-id": process.env.REACT_APP_X_CLIENT_ID
  }

  try {
    let response
    response = await axios({
      method: method,
      url: process.env.REACT_APP_API_IKEA + url,
      ...body ? { data: data } : { params: data },
      headers: header,
    });

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      const axiosError = {
        response: error.name,
        message: error.message
      }
      return axiosError
    } else if (error.response.status === 404) {
      window.location.href = "/not-found"
    } else {
      return error
    }

  }
}