import { apiFetch } from '../configuration/apiFetch';
import { createMsgToast } from '../store/globalSlice';
import { store } from '../store/store';


export const getStoreOptions =  () => {
  const fetchSettings = {
    method: "GET",
    url: 'stores',
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      response.forEach(sto =>{
        sto.label = sto.name
        sto.value = sto.id
      })
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getMotions =  (params) => {
  const fetchSettings = {
    method: "GET",
    url: 'motions',
    params
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getMotionsDynamic =  (params) => {
  const fetchSettings = {
    method: "GET",
    url: 'motions/dynamic',
    params
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getStatistics =  () => {
  const myStore = JSON.parse(window.localStorage.getItem('myStore'))
  const fetchSettings = {
    method: "GET",
    url: `statistics/goals/${myStore.id}`
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getMotion =  (motionId) => {
  const myStore = JSON.parse(window.localStorage.getItem('myStore'))
  const fetchSettings = {
    method: "GET",
    url: `motion/${motionId}`,
    params: {storeId: myStore.id}
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      resolve(response)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}

export const getStoreMaps =  (storeId) => {
  const fetchSettings = {
    method: "GET",
    url: `maps/${storeId}`,
    params: {
      showImage: false
    }
  }
  const dataFetch = new Promise ((resolve, reject) => {
    apiFetch(fetchSettings).then((response)=>{
      if(response.response) throw response
      const options = response.map(m => ({value: m.id, label: m.name}))
      resolve(options)
    }).catch((response)=>{
      //reject(response.message)
      store.dispatch(createMsgToast({body: response.message, title:"Error", variant:"negative"}))
    })
  })
  return dataFetch
}