/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";


export default function PrivateRoutes({children}) {
  const isLogged = useSelector((state) => state.global.login);
  const navigate = useNavigate()

  useEffect(() => {
    console.log("LOGIN",isLogged)
    if(!isLogged) navigate("/login")
  },[isLogged])

  return (isLogged)
  ? children
  : <Navigate to="/login" />
}

PrivateRoutes.propTypes = {
  children: PropTypes.object
}